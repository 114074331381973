import _objectSpread from "/Users/teerapatcheung/Desktop/0-Project/logistics/logistics_metronic_vue/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapGetters, mapState } from "vuex";
import { LANDING_URL } from "@/config";
export default {
  name: "login-1",
  data: function data() {
    return {
      LANDING_URL: LANDING_URL,
      state: "signin",
      form: {
        email: "",
        password: ""
      }
    };
  },
  mounted: function mounted() {
    if (this.isAuthenticated) {
      this.$router.push({
        name: "dashboard"
      });
    }
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState({
    errors: function errors(state) {
      return state.auth.errors;
    }
  })), mapGetters(["currentUser", "isAuthenticated", "layoutConfig"])), {}, {
    backgroundImage: function backgroundImage() {
      return process.env.BASE_URL + "media/svg/illustrations/login-visual-1.svg";
    },
    loaderLogo: function loaderLogo() {
      return this.layoutConfig("loader.logo");
    }
  })
};